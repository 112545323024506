import React, { useState } from "react";
import {
  List,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Dialog,
} from "@mui/material";
import OrganiserEditPrice from "../../screens/OrganiserEditPrice";

const MenuModal = ({ menu, vendorId }) => {
  const [showAdminMenuEditScreen, setShowAdminMenuEditScreen] = useState(false);
  const [mealProp, setMealProp] = useState(null);

  return (
    <Box sx={{ overflowY: "auto", maxHeight: 800, px: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        {menu.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
              <CardMedia
                component="img"
                sx={{ height: 200, objectFit: "cover" }}
                image={item.image || "/placeholder-image.jpg"}
                alt={item.name}
              />
              <CardContent>
                {(!item.image || !item.description) && (
                  <Typography color="error" variant="body2">
                    {`* ${!item.image ? "Meal image is missing" : ""} ${
                      !item.description ? "Meal description is missing" : ""
                    }`}
                  </Typography>
                )}
                <Typography variant="h6" fontWeight="bold">
                  {item.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Price: ${item.price}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 2, width: "100%" }}
                  onClick={() => {
                    setShowAdminMenuEditScreen(true);
                    setMealProp(item);
                  }}
                >
                  Edit
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Price Modal */}
      <Dialog open={showAdminMenuEditScreen} onClose={() => setShowAdminMenuEditScreen(false)} fullWidth maxWidth="sm">
        {mealProp && <OrganiserEditPrice menu={mealProp} vendorId={vendorId} />}
      </Dialog>
    </Box>
  );
};

export default MenuModal;
