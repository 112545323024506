import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import { PDFViewer } from "@react-pdf/renderer";
import RunsheetPdfCreator from "../../components/PdfComponent/RunSheetPdfCreator";
import ReactPDF from "@react-pdf/renderer";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";

import VendorNavBar from "../VendorApplyEventScreen/Component/VendorNavBar";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {
  store,
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import {
  Form,
  Container,
  Button,
  Row,
  Col,
  Card,
  Modal,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import DatePicker from "react-datepicker";
import Pagination from "react-bootstrap/Pagination";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { AWS_URL } from "../../assets/Constants";
import { TextField } from "material-ui";
import SignaturePad from "../ReactSignatureCanvas/index.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import "./SignaturePad.css";

import { black } from "material-ui/styles/colors";

class OperatorContractScreen extends Component {
  constructor(props) {
    super(props);
  this.state = {
    isAgreed: false,
    isWitnessAgreed: false,
    showModal: false,
    signatureData: null,
    operatorSign: "",
    contract_id: "",
    fileName: "",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    bookingRequests: [],
    operatorContracts: [],
    operatorName: "",
    witnessName: "",
    inviteVendorModal: true,
    vendors: [],
    nonCuisineVendors: [],

    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
    completed_payments: 0,
    resetFilter:
      getFromLocalStorage("resetFilter") != "undefined" &&
      getFromLocalStorage("resetFilter") != "" &&
      getFromLocalStorage("resetFilter") != null
        ? getFromLocalStorage("resetFilter")
        : "false",
    pageSelected: 1,
    operatorEmail:
      getFromLocalStorage("operatorEmail") &&
      getFromLocalStorage("operatorEmail") != ""
        ? getFromLocalStorage("operatorEmail")
        : "",
    operatorPassword:
      getFromLocalStorage("operatorPassword") &&
      getFromLocalStorage("operatorPassword") != ""
        ? getFromLocalStorage("operatorPassword")
        : "",
    vendor_ids: [],
    selectedVendorId: "",
  };
  }


  handleAgreementChange = (event) => {
    const isAgreed = event.target.checked;
    this.setState({ isAgreed, showModal: isAgreed });
  };

  handleWitnessAgreementChange = (event) => {
    const isWitnessAgreed = event.target.checked;
    this.setState({ isWitnessAgreed, showWitnessModal: isWitnessAgreed });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleCloseWitnessModal = () => {
    this.setState({ showWitnessModal: false });
    this.setState({ isWitnessAgreed: false });
    this.setState({ isAgreed: false });
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  saveSignature = (signatureData) => {
    this.setState({ vendorSignature: signatureData });
  };
  
  saveWitnessSignature = (signatureData) => {
    this.setState({ witnessSignature: signatureData });
  };
  addSignaturesToPDF = async (contractPDFUrl,id, vendor, event_name) => {
    const { vendorSignature, witnessSignature, operatorName, witnessName, vendorPosition } = this.state;
    const file_name = `vendor signed contract ${vendor}-${event_name}`
    this.setState({ contract_id: id, fileName: file_name });
    
    if (!vendorSignature || !witnessSignature) {
      alert("Both Vendor and Witness must sign before proceeding!");
      return;
    }
  
    try {
      const pdfResponse = await fetch(contractPDFUrl);
      const pdfBytes = await pdfResponse.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      
      const vendorSigImage = await pdfDoc.embedPng(vendorSignature);
      const witnessSigImage = await pdfDoc.embedPng(witnessSignature);
      
      const pages = pdfDoc.getPages();
      const targetPage = pages[18]; 
      const targetPageWitness = pages[19]; 
  
      // Add Signatures
      targetPage.drawImage(vendorSigImage, { x: 100, y: 120, width: 100, height: 30 });
      targetPageWitness.drawImage(witnessSigImage, { x: 100, y: 750, width: 100, height: 30 });
  
      // Add Text for Name, Position, and Date
      targetPage.drawText(`${operatorName}`, { x: 120, y: 110, size: 9,  });
      targetPage.drawText(`${moment().format("DD MM YYYY")}`, { x: 250, y: 135, size: 9,  });
  
      targetPage.drawText(`${vendor}`, { x: 180, y: 200, size: 9,  });
      targetPage.drawText(`${vendorPosition}`, { x: 250, y: 110, size: 9,  });
      
      targetPageWitness.drawText(`${witnessName}`, { x: 220, y: 770, size: 9,  });
      targetPageWitness.drawText(`${moment().format("DD MM YYYY")}`, { x: 100, y: 740, size: 9,  });
  
      // Save and Update State
      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedPdfBlob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
      const signedPdfUrl = URL.createObjectURL(modifiedPdfBlob);
  
      this.setState({ signedPdfUrl });
    } catch (error) {
      alert("Error processing the PDF");
      console.error("PDF Processing Error:", error);
    }
  };
  

  renderSignedPDFPreview() {
    const { signedPdfUrl } = this.state;
    if (!signedPdfUrl) return null; // No signed PDF yet
  
    return (
      <div>
        <h3>Preview Signed PDF</h3>
        <iframe
          src={signedPdfUrl}
          style={{ width: "100%", height: "500px", border: "1px solid #007bff" }}
        />
        <button onClick={this.uploadSignedPDF}>Upload Signed PDF </button>
      </div>
    );
  }
  
  
  // saveWitnessSignature = (signatureData, id, event) => {
  //   if (this.state.witnessName === "") {
  //     alert("Please enter your name");
  //   } else {
  //     this.setState({ signatureData }, () => {
  //       // this.sendSignatureToServer(id, event, signatureData); // Assuming you modify this function to accept id and event
  //       // this.handleCloseWitnessModal();
  //       // this.setState({ isWitnessAgreed: false });
  //       // this.setState({ isAgreed: false });
  //     });
  //   }
  // };


  uploadSignedPDF = async () => {
    const { signedPdfUrl, operatorName, witnessName, vendorPosition,fileName } = this.state;
  
    if (!signedPdfUrl) {
      alert("Generate the signed PDF first!");
      return;
    }
  
    try {
      const response = await fetch(signedPdfUrl);
      const pdfBlob = await response.blob();
      const pdfFile = new File([pdfBlob], `${fileName}signed_contract.pdf`, { type: "application/pdf" });
  
      // Prepare FormData
      const formData = new FormData();
      formData.append("signed_contract", pdfFile);
      formData.append("vendor_name", operatorName); // Vendor Name
      formData.append("vendor_position", vendorPosition); // Vendor Position
      formData.append("witness_name", witnessName); // Witness Name
      formData.append("date_signed", moment().format("DD MM YYYY")); // Date in DD MM YYYY format
      formData.append("vendor_signature", this.state.vendorSignature); // Append the contract ID
      formData.append("witness_signature", this.state.witnessSignature); // Append the contract ID
      formData.append("contract_id", this.state.contract_id); // Append the contract ID
  
      // Upload the signed contract
      const uploadResponse = await axios.post(
        config.BASE_URI + apiPaths.uploadSignedContract,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (uploadResponse.data.success) {
        alert("✅ Signed PDF uploaded successfully!");
      } else {
        alert("❌ Failed to upload signed PDF.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert("❌ Error uploading signed PDF.");
    }
  };
  
  

  sendSignatureToServer = async (id, event, signatureData) => {
    try {
      // Convert base64 to Blob then to a File for uploading
      const base64Response = await fetch(signatureData); // Use the signatureData parameter
      const blob = await base64Response.blob();
      const file = new File([blob], "witness_signature.jpg", {
        type: "image/jpeg",
      });

      const base64Response1 = await fetch(this.state.operatorSign); // Use the signatureData parameter
      const blob1 = await base64Response1.blob();
      const file1 = new File([blob1], "operator_signature.jpg", {
        type: "image/jpeg",
      });

      let currentDate = moment().format("DD MMM YYYY");

      // Prepare FormData with the signature file and additional data
      const formData = new FormData();
      formData.append("witness_signature", file);
      formData.append("operator_signature", file1);
      formData.append("vendor_id", this.state.selectedVendorId);
      formData.append("contract_id", this.state.contract_id); // Append the contract ID
      formData.append("event_id", event); // Append the event ID
      formData.append("operator_name", this.state.operatorName);
      formData.append("witness_name", this.state.witnessName);
      formData.append("timestamp", moment(currentDate).format("DD MMM YYYY"));

      // Send the signature file and additional data to the server
      const response = await axios.post(
        config.BASE_URI + apiPaths.signContract,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success === true) {
        alert("You have successfully signed the contract.");
        this.setState({
          selectedVendorId: "",
          operatorName: "",
          witnessName: "",
          isAgreed: false,
          isWitnessAgreed: false,
          showModal: false,
          showWitnessModal: false,
          operatorSign: "",
        });
        window.location.reload();
      } else {
        alert("Error sending signature to server:");
        window.location.reload();
      }
    } catch (error) {
      alert("There was an error processing your signature. Please try again.");
      window.location.reload();
    }
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };
  componentWillMount() {
    const { startDate, endDate } = this.state;
    if (startDate.toString() == "Invalid Date") {
      this.setState({ startDate: new Date() });
    }
    if (endDate.toString() == "Invalid Date") {
      this.setState({ endDate: new Date() });
    }
  }

  componentDidMount() {
    const { isSuperAdminLoggedIn } = this.state;
    this.fetch_vendor_ids();
    this.getContractData(this.state.requestState);
  }

  login = () => {
    const { email, password } = this.state;
    this.setState({ isLoadingVendor: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "admin_login",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingVendor: false });
        if (dataResponse.success == true) {
          this.setState({ isSuperAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("isSuperAdminLoggedIn", "true");
            saveToLocalStorage("email", email);
            saveToLocalStorage("password", password);
            this.getContractData(this.state.requestState);
          });
        } else {
          alert("Invalid email or password! Please try again!");
        }
      })
      .catch((err) => {
        this.setState({ isLoadingVendor: false });
      });
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.reload();
  };



  getContractData = (requestState) => {
    console.log(requestState);
    const { startDate, endDate, resetFilter, pageSelected } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.operatorData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_contracts_for_operator",
          vendor_id: this.state.selectedVendorId,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({ operatorContracts: dataResponse.contracts });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { isOperatorLoggedIn, stockUserLoggedIn } = this.state;
    return (
      <div>
        {this.renderNavBar()}
        {stockUserLoggedIn == "false" || isOperatorLoggedIn == "false"
          ? this.renderHome()
          : this.renderHome()}
        <Container></Container>
      </div>
    );
  }

  fetch_vendor_ids = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_operator_details_for_stall_ids",
          operator_id: JSON.parse(getFromLocalStorage("Operator"))[0].id,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URI + apiPaths.adminData, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          this.setState({ vendor_ids: result.stalls });
        } else {
          alert(result.error);
        }
      });
  };

  renderNavBar() {
    const { stockUserLoggedIn, isOperatorLoggedIn, stockUserData } = this.state;
    return (
      <VendorNavBar isOperatorLoggedIn={isOperatorLoggedIn}></VendorNavBar>
    );
  }

  renderLogin() {
    const { email, password, isEventCoordinatorLoggedIn } = this.state;
    return (
      <div>
        <Container>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(text) => {
                this.setState({ email: text.target.value });
              }}
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(text) => {
                this.setState({ password: text.target.value });
              }}
            />
          </Form.Group>

          <Button
            style={{
              width: "100%",
              marginBottom: 20,
              marginTop: 10,
              backgroundColor: "#ef3f6d",
            }}
            className="button-enabled-pink-style"
            onClick={() => {
              this.login();
            }}
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Container>
      </div>
    );
  }

  renderHome() {
    const {
      bookingRequests,
      operatorContracts,
      operatorName,
      witnessname,
      availableStatus,
      isLoadingChangeBookingStatus,
      adminNotesChanged,
      isLoading,
      requestState,
      isOperatorLoggedIn,
      startDate,
      endDate,
      completed_payments,
      pageSelected,
      vendor_ids,
      selectedVendorId,
      stallNameNotes,
      isAgreed,
      isWitnessAgreed,
      showModal,
      showWitnessModal,
    } = this.state;

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} className="header">
                  <h2>Contracts</h2>
                  <h3>
                    Operator{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].first_name}{" "}
                    {JSON.parse(getFromLocalStorage("Operator"))[0].last_name}
                  </h3>

                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    {/* <Form.Label>Stall name</Form.Label> */}

                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Label>Select Vendor</Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={selectedVendorId}
                        onChange={(e) => {
                          this.setState(
                            { selectedVendorId: "" + e.target.value },
                            () => {
                              this.getContractData();
                            }
                          );
                          saveToLocalStorage(
                            "selectedVendorId",
                            "" + e.target.value
                          );
                        }}
                      >
                        {vendor_ids && vendor_ids.length == 1 ? (
                          <option value={vendor_ids[0].user_ptr_id}>
                            {vendor_ids[0].title}
                          </option>
                        ) : (
                          <>
                            <option value={0}>Select Vendor</option>
                            {vendor_ids &&
                              vendor_ids.map((e) => {
                                return (
                                  <option value={"" + e.user_ptr_id}>
                                    {e.title}
                                  </option>
                                );
                              })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
              {operatorContracts.map((item, requestIndex) => (
                <Accordion key={item.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item.id}-content`}
                    id={`panel${item.id}-header`}
                  >
                    <Typography>
                      <h5>Event Name: {item.event__name}</h5>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      {item.draft_event_agreement ? (
                        <iframe
                          className="responsive-iframe"
                          src={`${AWS_URL}/${item.draft_event_agreement}#toolbar=0`}
                          style={{
                            width: "100%",
                            height: "100vh", // Stretches the iframe to fit the viewport height
                            border: "none", // Removes borders
                          }}
                          frameBorder="0"
                          title="PDF Contract"
                        >
                          View PDF
                        </iframe>
                      ) : (
                        <Typography>No contract available</Typography>
                      )}
                      <div>
  <label style={{ display: "inline-flex", alignItems: "center", whiteSpace: "nowrap" }}>
    <input
      type="checkbox"
      checked={this.state.isAgreed}
      onChange={this.handleAgreementChange}
      style={{ marginRight: "4px" }} 
    />
    I confirm that both the Vendor and Witness agree to the above terms
  </label>

  <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>Signature Confirmation</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="guide-section" style={{ padding: "10px", border: "1px solid #ccc", borderRadius: "5px", marginBottom: "15px", backgroundColor: "#f9f9f9" }}>
      <h5>📜 How to Sign and Submit Your Contract</h5>
      <p><strong>Step 1: Enter Your Details</strong></p>
      <ul>
        <li>✅ <strong>Vendor Name:</strong> Enter your full name.</li>
        <li>✅ <strong>Witness Name:</strong> Enter the witness's full name.</li>
        <li>✅ <strong>Vendor Position:</strong> Provide your job title (e.g., Manager, Owner).</li>
        <li>✅ <strong>Current Date:</strong> This will be auto-filled.</li>
      </ul>
      <p><strong>Step 2: Sign Below</strong></p>
      <ul>
        <li>✍️ <strong>Vendor Signature:</strong> Use the first box.</li>
        <li>✍️ <strong>Witness Signature:</strong> Use the second box.</li>
        <li>⚠️ If using a mouse, click and hold to draw. On mobile, use your finger.</li>
      </ul>
      <p><strong>Step 3: Generate and Submit</strong></p>
      <ul>
        <li>🔄 Click <strong>"Generate Signed PDF"</strong> to preview.</li>
        <li>📤 If everything looks good, click <strong>"Upload Signed PDF"</strong> to submit.</li>
      </ul>
    </div>

    {/* Vendor Name Input */}
    <div className="name-field">
      <label htmlFor="operatorName">Vendor Name:</label>
      <input
        type="text"
        id="operatorName"
        onChange={(e) => this.setState({ operatorName: e.target.value })}
        placeholder="Enter Vendor Name"
      />
    </div>

    {/* Witness Name Input */}
    <div className="name-field">
      <label htmlFor="witnessName">Witness Name:</label>
      <input
        type="text"
        id="witnessName"
        onChange={(e) => this.setState({ witnessName: e.target.value })}
        placeholder="Enter Witness Name"
      />
    </div>

    {/* Vendor Position Input */}
    <div className="name-field">
      <label htmlFor="vendorPosition">Vendor Position:</label>
      <input
        type="text"
        id="vendorPosition"
        onChange={(e) => this.setState({ vendorPosition: e.target.value })}
        placeholder="Enter Vendor Position (e.g., Manager)"
      />
    </div>

    {/* Current Date Display */}
    <div className="name-field">
      <label>Current Date: </label>
      <span>{moment().format("DD MM YYYY")}</span>
    </div>

    {/* Signature Pads */}
    <div className="signature-section">
      <h5>✍️ Vendor Signature</h5>
      <SignaturePad onSave={(signatureData) => this.saveSignature(signatureData)} />

      <h5>✍️ Witness Signature</h5>
      <SignaturePad onSave={(signatureData) => this.saveWitnessSignature(signatureData)} />
    </div>

    {/* Generate Signed PDF Button */}
    <button
      onClick={() => this.addSignaturesToPDF(`${AWS_URL}/${item.draft_event_agreement}`, item.id, item.vendor__title, item.event__name)}
      disabled={!this.state.operatorName || !this.state.witnessName || !this.state.vendorPosition || !this.state.vendorSignature || !this.state.witnessSignature}
      style={{ marginTop: "10px ; backgroundColor: #007bff" }}
    >
      Generate Signed PDF
    </button>

    {/* Preview Signed PDF */}
    {this.renderSignedPDFPreview()}
  </Modal.Body>

  <Modal.Footer>
    <button onClick={this.handleCloseModal}>Close</button>
  </Modal.Footer>
</Modal>

</div>

                    </>
                  </AccordionDetails>
                </Accordion>
              ))}
             
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }






}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(OperatorContractScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
