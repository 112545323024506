import React, { Component } from "react";
import { connect } from "react-redux";
import AdminNavBar from "../../components/UI/AdminNavBar";
import Footer from "../../components/UI/Footer";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage,
} from "../../store";
import * as Sentry from "@sentry/react";
import { Form, Container, Button, Row, Col, Modal } from "react-bootstrap";
import config from "../../services/apiConfig";
import { apiPaths } from "../../services/apiPath";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "./utils.js";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EventOrganiserBarGraph } from "../EventOrganiserOverviewScreen/EventOrganiserBarGraph";
import { EventPOSChart } from "../EventOrganiserOverviewScreen/EventPOSBarGraph";

import { EventSalesBarGraph } from "../EventOrganiserOverviewScreen/EventSalesBarGraph";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import { EventOrganiserVendorsPieChart } from "../EventOrganiserOverviewScreen/EventOrganiserVendorsPieChart";
import TableData from "../../components/UI/ItemsSoldTable";
import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { generateWeeks } from "../OperatorRunSheets/utils.js";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import SalesTable from "./Components/SalesTabel";
import SalesByEventTabel from "./Components/SalesByEventTabel";
import MealStats from "./Components/MealStats";

class AdminExternalOperatorsDashboardScreen extends Component {
  state = {
    isSuperAdminLoggedIn:
      getFromLocalStorage("isSuperAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != "" &&
      getFromLocalStorage("isSuperAdminLoggedIn") != null
        ? getFromLocalStorage("isSuperAdminLoggedIn")
        : "false",
    isAccountantLoggedIn:
      getFromLocalStorage("isAccountantLoggedIn") != "undefined" &&
      getFromLocalStorage("isAccountantLoggedIn") != "" &&
      getFromLocalStorage("isAccountantLoggedIn") != null
        ? getFromLocalStorage("isAccountantLoggedIn")
        : "false",
    isStockAdminLoggedIn:
      getFromLocalStorage("isStockAdminLoggedIn") != "undefined" &&
      getFromLocalStorage("isStockAdminLoggedIn") != "" &&
      getFromLocalStorage("isStockAdminLoggedIn") != null
        ? getFromLocalStorage("isStockAdminLoggedIn")
        : "false",
    isEventCoordinatorLoggedIn:
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != "" &&
      getFromLocalStorage("isEventCoordinatorLoggedIn") != null
        ? getFromLocalStorage("isEventCoordinatorLoggedIn")
        : "false",
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    isOperatorLoggedIn:
      getFromLocalStorage("isOperatorLoggedIn") != "undefined" &&
      getFromLocalStorage("isOperatorLoggedIn") != "" &&
      getFromLocalStorage("isOperatorLoggedIn") != null
        ? getFromLocalStorage("isOperatorLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    events: [],
    orders: [],
    tempOrders: [],
    vendors: [],
    isLoading: false,
    showPassword: false,
    selectedEventId:
      getFromLocalStorage("selectedEventId") != "undefined" &&
      getFromLocalStorage("selectedEventId") != "" &&
      getFromLocalStorage("selectedEventId") != null
        ? getFromLocalStorage("selectedEventId")
        : "0",
    selectedVendorId:
      getFromLocalStorage("selectedVendorId") != "undefined" &&
      getFromLocalStorage("selectedVendorId") != "" &&
      getFromLocalStorage("selectedVendorId") != null
        ? getFromLocalStorage("selectedVendorId")
        : "0",
        selectedFilterType: "date", // Options: 'week', 'month', 'date'
  weeks: generateWeeks(new Date(), moment().add(12, "months").toDate()), // Pre-calculated weeks
  selectedStartWeek: null,
  selectedEndWeek: null,
  selectedMonth: moment().format("YYYY-MM"),
    selectedStartOrderId: "-1",
    selectedEndOrderId: "-1",
    selectedZ: "-1",
    selectedEvent: {},
    availableStatus: [
      "Received",
      "Cooking",
      "Ready for pickup",
      "On its way",
      "Finished",
      "Canceled",
    ],
    isLoadingChangeOrderStatus: false,
    selectedOrderNewStatus: "",
    selectedOrder: null,
    isOpenOrderStatusChangeModal: false,
    isLoadingChangeOrderStatus: false,
    total_sales: 0,
    total_orders: 0,
    canceled_sales: 0,
    canceled_orders: 0,
    totalCash: 0,
    totalCard: 0,
    totalCashOrders: 0,
    totalCardOrders: 0,
    totalCashSurcharge: 0,
    totalCardSurcharge: 0,
    totalTransactionFee: 0,
    totalCash: 0,
    total_voucher_value: 0,
    canceled_sales_cash: 0,
    canceled_sales_card: 0,
    z_reports: [],
    selectedDate: "-1",
    meal_freq: [""],
    newMealFrequency: [],
    salesByVendorObj: [],
    salesByVendorName: [],
    salesByVendorTotal: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    salesByHoursNew: {},
    salesByEventName: [],
    salesByEventTotal: [],
    salesByEventObj: [],
    salesByPOSName: [],
    salesByPOSTotal: [],
    percentageChangeLastYear: 0,
    percentageChangeLastMonth: 0,
    percentageChangeLastWeek: 0,
    lastYearDatesInfo: {},
    lastMonthDatesInfo: {},
    totalVoucherSurcharge:0,
    lastWeekDatesInfo: {},
    salesModal: false,
    ordersModal: false,
    averageModal: false,
    refundsModal: false,
    refundsOrderModal: false,
    startDate:
      getFromLocalStorage("startDate") != "undefined" &&
      getFromLocalStorage("startDate") != "" &&
      getFromLocalStorage("startDate") != null &&
      getFromLocalStorage("startDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("startDate"))
        : new Date(),
    endDate:
      getFromLocalStorage("endDate") != "undefined" &&
      getFromLocalStorage("endDate") != "" &&
      getFromLocalStorage("endDate") != null &&
      getFromLocalStorage("endDate").toString() != "Invalid Date"
        ? new Date(getFromLocalStorage("endDate"))
        : new Date(),
  };

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate);
    });
  };

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate);
    });
  };

  componentDidMount() {
    const {
      isSuperAdminLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    if (
      isSuperAdminLoggedIn === "true" ||
      isAccountantLoggedIn === "true" ||
      isStockAdminLoggedIn === "true"
    ) {
      this.getVendorsInEvent();
    } else {
      window.location.href = "/admin";
    }
  }

  setMonthFilter = (month) => {
    const start = moment(month).startOf("month").toDate();
    const end = moment(month).endOf("month").toDate();
    this.setState({ startDate: start, endDate: end, selectedFilterType: "month" }, this.getOrders);
  };

  setWeekFilter = (startWeek, endWeek) => {
    const start = this.state.weeks[startWeek].start;
    const end = this.state.weeks[endWeek].end;
    this.setState({ startDate: start, endDate: end, selectedFilterType: "week" }, this.getOrders);
  };

  logout = () => {
    deleteFromLocalStorage("isSuperAdminLoggedIn", "false");
    deleteFromLocalStorage("isEventCoordinatorLoggedIn", "false");
    deleteFromLocalStorage("isAccountantLoggedIn", "false");
    deleteFromLocalStorage("isStockAdminLoggedIn", "false");
    this.setState({ isEventCoordinatorLoggedIn: "false" });
    this.setState({ isAccountantLoggedIn: "false" });
    this.setState({ isStockAdminLoggedIn: "false" });
    this.setState({ isSuperAdminLoggedIn: "false" });
    window.location.href = "/admin";
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleCloseAverageModal = () => {
    this.setState({ averageModal: false });
  };
  handleShowAverageModal = () => {
    this.setState({ averageModal: true });
  };
  handleShowSalesModal = () => {
    this.setState({ salesModal: true });
  };

  handleCloseSalesModal = () => {
    this.setState({ salesModal: false });
  };
  handleShowOrdersModal = () => {
    console.log("clicked");
    this.setState({ ordersModal: true });
  };

  handleCloseOrdersModal = () => {
    this.setState({ ordersModal: false });
  };
  handleCloseRefundsModal = () => {
    this.setState({ refundsModal: false });
  };
  handleShowRefundsModal = () => {
    this.setState({ refundsModal: true });
  };
  handleCloseRefundsOrderModal = () => {
    this.setState({ refundsOrderModal: false });
  };
  handleShowRefundsOrderModal = () => {
    this.setState({ refundsOrderModal: true });
  };
  setItemPercentage = () => {
    const newMealFrequency = this.state.meal_freq.map((item) => {
      const percentage = ((item.price / this.state.total_sales) * 100).toFixed(
        2
      );
      return {
        ...item,
        percentage,
      };
    });
    this.setState({ newMealFrequency: newMealFrequency });
    //return true
  };

  getBackgroundColor(length) {
    var bgColor = [];
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++) rgb.push(Math.floor(Math.random() * 255));
      bgColor.push("rgb(" + rgb.join(",") + ")");
    }

    return bgColor;
  }

  getAllEvents = () => {
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_all_events",
          email: email,
          password: password,
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoading: false });
        if (dataResponse.success == true) {
          this.setState(
            {
              events: dataResponse.events.sort(
                (a, b) => {
                  if (
                    moment(a.start_date, "DD MMM YYYY").unix() >
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return -1;
                  } else if (
                    moment(a.start_date, "DD MMM YYYY").unix() <
                    moment(b.start_date, "DD MMM YYYY").unix()
                  ) {
                    return 1;
                  } else {
                    return 0;
                  }
                },
                () => {}
              ),
            },
            () => {
              if (this.state.selectedEventId != 0) {
                this.getVendorsInEvent();
              }
              this.getVendorsInEvent();
            }
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };


 

  getOrders = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;



  

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures_for_operators",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: this.state.startDate.toString(),
          end_date: this.state.endDate.toString(),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            orders: dataResponse.orders,
            tempOrders: dataResponse.orders,
            selectedEvent: dataResponse.event,
            total_sales: dataResponse.total_sales,
            total_orders: dataResponse.total_orders,
            canceled_sales: dataResponse.canceled_sales,
            canceled_orders: dataResponse.canceled_orders,
            totalCash: dataResponse.total_cash_sales,
            totalCard: dataResponse.total_card_sales,
            totalCardOrders: dataResponse.card_new_count,
            totalCashOrders: dataResponse.cash_new_count,
            canceled_sales_cash: dataResponse.canceled_sales_cash,
            canceled_sales_card: dataResponse.canceled_sales_card,
            z_reports: dataResponse.z_reports,
            meal_freq: dataResponse.meal_freq,
            salesByVendor: dataResponse.sales_by_vendor_obj,
            salesByEvent: dataResponse.sales_by_events_obj,
            salesByHours: dataResponse.sorted_hours_sales,
            salesByVendorObj: dataResponse.sales_by_vendor_obj,
            salesByEventObj: dataResponse.sales_by_events_obj,
            total_voucher_value: dataResponse.total_voucher_value,
            salesByPosObj: dataResponse.sales_by_pos_obj,
            percentageChangeLastYear: dataResponse.percentage_change_year,
            percentageChangeLastMonth: dataResponse.percentage_change_month,
            percentageChangeLastWeek: dataResponse.percentage_change_week,
            totalCashSurcharge: dataResponse.total_cash_surcharge,
            totalCardSurcharge: dataResponse.total_card_surcharge,
            totalTransactionFee: dataResponse.total_transaction_fee,
            totalVoucherSurcharge: dataResponse.total_voucher_surcharge
          });
          var salesByHoursObj = dataResponse.sorted_hours_sales;
          this.getOrdersPartTwo();
        }
        let salesBYVendorName = [];
        let salesBYVendorTotal = [];
        var salesByHoursLabelsTemp = [];
        var salesByHoursDataTemp = [];
        var salesBYEventName = [];
        var salesBYEventTotal = [];
        let salesByPOSName = [];
        let salesByPOSTotal = [];
        // alert("salesByVendor" + dataResponse.sales_by_vendor_obj)

        const getFirstAndLastDates = (datesArray) => {
          const firstDate = datesArray[0];
          const lastDate = datesArray[datesArray.length - 1];
          return { firstDate, lastDate };
        };

        const lastYearDatesInfo = getFirstAndLastDates(
          dataResponse.last_year_dates
        );
        const lastMonthDatesInfo = getFirstAndLastDates(
          dataResponse.last_month_dates
        );
        const lastWeekDatesInfo = getFirstAndLastDates(
          dataResponse.last_week_dates
        );

        this.setState({
          lastYearDatesInfo: lastYearDatesInfo,
          lastMonthDatesInfo: lastMonthDatesInfo,
          lastWeekDatesInfo: lastWeekDatesInfo,
        });

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_vendor_obj
        )) {
          console.log(`${key}: ${salesBYVendorName.push(key)}`);
          console.log(`${value}: ${salesBYVendorTotal.push(value)}`);
        }

        this.setState({ salesByVendorName: salesBYVendorName });
        this.setState({ salesByVendorTotal: salesBYVendorTotal });

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_events_obj
        )) {
          console.log(`${key}: ${salesBYEventName.push(key)}`);
          console.log(`${value}: ${salesBYEventTotal.push(value)}`);
        }

        this.setState({ salesByEventName: salesBYEventName });
        this.setState({ salesByEventTotal: salesBYEventTotal });

        for (const [key, value] of Object.entries(
          dataResponse.sales_by_pos_obj
        )) {
          console.log(`${key}: ${salesByPOSName.push(key)}`);
          console.log(`${value}: ${salesByPOSTotal.push(value)}`);
        }

        this.setState({ salesByPOSName: salesByPOSName });
        this.setState({ salesByPOSTotal: salesByPOSTotal });

        for (let [key, value] of Object.entries(salesByHoursObj)) {
          salesByHoursLabelsTemp.push(key);
        }

        salesByHoursLabelsTemp.sort();
        var len = salesByHoursLabelsTemp.length;
        for (var i = 0; i < len; i++) {
          var k = salesByHoursLabelsTemp[i];
          salesByHoursDataTemp.push(salesByHoursObj[k]);
        }
        this.setState({ salesByHoursLabels: salesByHoursLabelsTemp });
        this.setState({ salesByHoursData: salesByHoursDataTemp });
        this.setItemPercentage();
        const tempData = this.createDataForGraph();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });
  };
  getOrdersPartTwo = () => {
    const {
      email,
      password,
      selectedEventId,
      selectedVendorId,
      selectedEndOrderId,
      selectedStartOrderId,
      selectedDate,
    } = this.state;

    this.setState({ isLoading: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures_for_operators_two",
          email: email,
          password: password,
          vendor: selectedVendorId,
          selected_start_order_id: selectedStartOrderId,
          selected_end_order_id: selectedEndOrderId,
          selected_date: selectedDate,
          start_date: getFromLocalStorage("startDate"),
          end_date: getFromLocalStorage("endDate"),
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success == true) {
          this.setState({
            salesByHoursNew: dataResponse.sorted_hours_sales,
          });
        } else {
          this.setState({
            salesByHoursNew: {},
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          salesByHoursNew: {},
        });
        this.setState({ isLoading: false });
      });
  };
  getVendorsInEvent = () => {
    const {
      email,
      password,
      selectedEventId,
      isOperatorLoggedIn,
      isSuperAdminLoggedIn,
      selectedVendorId,
    } = this.state;
    this.setState({ isLoading: false });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (isOperatorLoggedIn == true) {
    } else {
      var raw = JSON.stringify({
        payload: {
          body: {
            query_type: "get_all_external_operators",
          },
        },
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((dataResponse) => {
          this.setState({ vendors: dataResponse.vendors }, () => {
            let vendorId = "0";
            this.setState({ selectedVendorId: vendorId }, () => {
              saveToLocalStorage("selectedVendorId", vendorId);
              // this.getOrders();
            });
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  onChangeOrderStatus = (e, order) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    this.setState(
      {
        selectedOrder: order,
        selectedOrderNewStatus: e.target.value,
        isOpenOrderStatusChangeModal: true,
      },
      () => {
        this.setState({ isLoadingChangeOrderStatus: false });
      }
    );
  };

  getDateRange = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    dates.push(moment(currDate).format("DD MMM YYYY"));
    var lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      dates.push(moment(currDate).format("DD MMM YYYY"));
    }

    return dates;
  };
  onChangeOrderStatusOnServer = (orderId, orderStatus) => {
    this.setState({ isLoadingChangeOrderStatus: true });
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "update_order_status",
          order_id: orderId,
          status: orderStatus,
        },
      },
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ isLoadingChangeOrderStatus: false });
        this.setState({ isOpenOrderStatusChangeModal: false });
        if (dataResponse.success) {
          alert("Changed Successfully");
          window.location.reload();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({
          isOpenOrderStatusChangeModal: false,
          isLoadingChangeOrderStatus: false,
        });
        alert("Something went wrong! Please try again");
        window.location.reload();
      });
  };

  render() {
    const {
      isSuperAdminLoggedIn,
      isEventCoordinatorLoggedIn,
      isAccountantLoggedIn,
      isStockAdminLoggedIn,
    } = this.state;
    return (
      <div>
        <AdminNavBar
          isSuperAdminLoggedIn={isSuperAdminLoggedIn}
          isEventCoordinatorLoggedIn={isEventCoordinatorLoggedIn}
          isAccountantLoggedIn={isAccountantLoggedIn}
          isStockAdminLoggedIn={isStockAdminLoggedIn}
          logout={this.logout}
        ></AdminNavBar>
        {!isSuperAdminLoggedIn || !isAccountantLoggedIn || !isStockAdminLoggedIn
          ? (window.location.href = "/admin")
          : this.renderHome()}
      </div>
    );
  }



  setPresetFilter = (preset) => {
    const presets = {
      today: { start_date: moment().startOf("day"), end_date: moment().endOf("day") },
      yesterday: {
        start_date: moment().subtract(1, "days").startOf("day"),
        end_date: moment().subtract(1, "days").endOf("day"),
      },
      this_week: { start_date: moment().startOf("isoWeek"), end_date: moment().endOf("isoWeek") }, // Monday as start of the week
      last_week: {
        start_date: moment().subtract(1, "week").startOf("isoWeek"),
        end_date: moment().subtract(1, "week").endOf("isoWeek"),
      },
      this_month: { start_date: moment().startOf("month"), end_date: moment().endOf("month") },
      last_month: {
        start_date: moment().subtract(1, "month").startOf("month"),
        end_date: moment().subtract(1, "month").endOf("month"),
      },
      this_year: { start_date: moment().startOf("year"), end_date: moment().endOf("year") },
      last_year: {
        start_date: moment().subtract(1, "year").startOf("year"),
        end_date: moment().subtract(1, "year").endOf("year"),
      },
    };


    const selectedPreset = presets[preset];
    if (selectedPreset) {
      this.setState(
        {
          startDate: selectedPreset.start_date.toDate(),
          endDate: selectedPreset.end_date.toDate(),
          selectedFilterType: "date",
        },
        this.getOrders
      );
    }
  };

  renderHome() {
    const percentageChangeMessage = (newValue, oldValue) => {
      const percentageChange = ((newValue - oldValue) / oldValue) * 100;
      return `${Math.abs(percentageChange).toFixed(2)}%`;
    };

    const {
      isLoading,
      events,
      selectedEventId,
      orders,
      tempOrders,
      selectedEvent,
      vendors,
      selectedVendorId,
      availableStatus,
      isLoadingChangeOrderStatus,
      total_orders,
      total_sales,
      canceled_orders,
      canceled_sales,
      canceled_sales_cash,
      canceled_sales_card,
      totalCash,
      totalCard,
      totalCashOrders,
      totalCardOrders,
      z_reports,
      selectedStartOrderId,
      selectedEndOrderId,
      total_voucher_value,
      selectedDate,
      startDate,
      endDate,
      salesByVendorObj,
      salesByEventObj,
      percentageChangeLastYear,
      percentageChangeLastMonth,
      percentageChangeLastWeek,
      newMealFrequency,
      meal_freq,
      salesByHoursNew,
      selectedFilterType,
      selectedStartWeek,
      weeks,
      totalCashSurcharge,
      totalCardSurcharge,
      totalTransactionFee,
      selectedEndWeek,
      selectedMonth
    } = this.state;
    console.log(meal_freq);
    // Calculate the total price of all items
    const total_price = meal_freq.reduce(
      (acc, item) => acc + item.price * item.count,
      0
    );

    // Update meal_freq array to include the "percentage" field
    const updatedMealFreq = meal_freq.map((item) => {
      const item_price = item.price * item.count;
      const item_percentage = ((item_price / total_price) * 100).toFixed(2);
      return { ...item, percentage: item_percentage };
    });
    console.log(updatedMealFreq);

    if (selectedEvent) {
    }
    const data = {
      labels: Object.keys(this.state.salesByHoursNew), // Extracting hour labels from the current year data
      datasets: [
        {
          label: "Sales By Hours",
          data: Object.values(this.state.salesByHoursNew), // Extracting sales data for current year
          backgroundColor: [
            "#488f31",
            "#fde987",
            "#f9b25f",
            "#ec7a4f",
            "#de425b",
            "#63ab70",
            "#adcc76",
            "#8f2d56",
            "#d95763",
            "#f2a154",
            "#f9d9a6",
            "#a2d6c4",
            "#4f86c6",
            "#f06eaa",
            "#5e5d5c",
          ],
          fill: true,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "hour",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    return (
      <div>
        {!isLoading ? (
          <>
            <Container
              style={{
                borderWidth: 1,
                borderColor: "grey",
                borderStyle: "solid",
                borderRadius: 5,
                padding: 15,
              }}
            >
              <Row>
                <Col xs={12} md={12} lg={12}>
                  {vendors && vendors.length > 0 ? (
                    <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                      <Form.Label>
                        Vendors {"(" + vendors.length + ")"}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={selectedVendorId}
                        onChange={(e) => {
                          this.setState(
                            { selectedVendorId: "" + e.target.value },
                            () => {
                              // this.getOrders();
                            }
                          );
                          saveToLocalStorage(
                            "selectedVendorId",
                            "" + e.target.value
                          );
                        }}
                      >
                        {vendors && vendors.length == 1 ? (
                          <option value={vendors[0].id}>
                            {vendors[0].first_name + " " + vendors[0].last_name}
                          </option>
                        ) : (
                          <>
                            <option value={0}>All Vendors</option>
                            {vendors &&
                              vendors.map((e) => {
                                return (
                                  <option value={e.id}>
                                    {e.first_name + " " + e.last_name}
                                  </option>
                                );
                              })}
                          </>
                        )}
                      </Form.Control>
                    </Form.Group>
                  ) : null}
                </Col>
              </Row>

              <Row className="filter-section mb-4">
      <Col xs={12} className="mb-3">
        <Form.Group>
          <Form.Label>Filter By</Form.Label>
          <Form.Control
            as="select"
            value={selectedFilterType}
            onChange={(e) => this.setState({ selectedFilterType: e.target.value })}
          >
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="date">Date Range</option>
          </Form.Control>
        </Form.Group>
      </Col>

      {selectedFilterType === "week" && (
        <>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Start Week</Form.Label>
              <Form.Control
                as="select"
                value={selectedStartWeek || ""}
                onChange={(e) => this.setState({ selectedStartWeek: e.target.value })}
              >
                <option value="" disabled>Select Start Week</option>
                {weeks.map((week, index) => (
                  <option key={index} value={index}>{week.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>End Week</Form.Label>
              <Form.Control
                as="select"
                value={selectedEndWeek || ""}
                onChange={(e) => this.setState({ selectedEndWeek: e.target.value })}
              >
                <option value="" disabled>Select End Week</option>
                {weeks.map((week, index) => (
                  <option key={index} value={index}>{week.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-3">
            <Button
              variant="primary"
              onClick={() => this.setWeekFilter(selectedStartWeek, selectedEndWeek)}
            >
              Apply Week Filter
            </Button>
          </Col>
        </>
      )}

      {selectedFilterType === "month" && (
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Select Month</Form.Label>
            <Form.Control
              as="select"
              value={selectedMonth}
              onChange={(e) => this.setMonthFilter(e.target.value)}
            >
              {Array(12)
                .fill(0)
                .map((_, index) => {
                  const month = moment().subtract(index, "months").format("MMMM YYYY");

                  return <option key={month} value={month}>{month}</option>;
                })}
            </Form.Control>
          </Form.Group>
        </Col>
      )}

      {selectedFilterType === "date" && (
        <>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={this.setStartDate}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={this.setEndDate}
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mt-3">
            <Button variant="primary" onClick={this.getOrders}>
              Apply Date Range Filter
            </Button>
          </Col>
        </>
      )}
    </Row>

    <Col xs={12} >
    <Button variant="secondary" onClick={() => this.setPresetFilter("today")}>Today</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("yesterday")}>Yesterday</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("this_week")}>This Week</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("last_week")}>Last Week</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("this_month")}>This Month</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("last_month")}>Last Month</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("this_year")}>This Year</Button>
            <Button variant="secondary" onClick={() => this.setPresetFilter("last_year")}>Last Year</Button>
</Col>

              {selectedVendorId != "0" ? (
                <Col xs={12} md={3} lg={3}>
                  <Form.Label>Select Time</Form.Label>
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        this.setState({ selectedDate: e.target.value }, () => {
                          this.getOrders();
                        });
                      }}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              ) : null}
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <h5>Total Sales: ${Number(total_sales).toFixed(2)}</h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowSalesModal}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5>
                    Total Orders:{" "}
                    {(
                      Number(totalCardOrders) + Number(totalCashOrders)
                    ).toFixed(2)}
                  </h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowOrdersModal}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5>
                    Total Avg Order: $
                    {(
                      Number(total_sales) /
                      (Number(totalCardOrders) + Number(totalCashOrders) + Number(totalCashSurcharge) + Number(totalCardSurcharge) + Number(totalTransactionFee))
                    ).toFixed(2)}
                  </h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowAverageModal}
                  >
                    See Details
                  </p>
                </Col>
              </Row>
              <Modal
                show={this.state.salesModal}
                onHide={this.handleCloseSalesModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Cash Sales: ${Number(totalCash).toFixed(2)}</p>
                  <p>Card Sales: ${Number(totalCard).toFixed(2)}</p>
                  <p>Voucher Sales: ${Number(this.state.total_voucher_value).toFixed(2)}</p>
                  <p>Cash Surcharge: ${Number(this.state.totalCashSurcharge).toFixed(2)}</p>
                  <p>Voucher Surcharge: ${Number(this.state.totalVoucherSurcharge).toFixed(2)}</p>
                  <p>Card Surcharge: ${Number(this.state.totalCardSurcharge).toFixed(2)}</p>
                  <p>Transaction Fees: ${Number(this.state.totalTransactionFee).toFixed(2)}</p>
                 
                  
                  <p>GST: ${((Number(total_sales) * 10) / 100).toFixed(2)}</p>
                  <p>
                    Total Sales(Surcharge+Transaction Fee): $
                    {Number(total_sales).toFixed(2)}
                  </p>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.ordersModal}
                onHide={this.handleCloseOrdersModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Cash Orders: {totalCashOrders} (
                    {(
                      (totalCashOrders / (totalCashOrders + totalCardOrders)) *
                      100
                    ).toFixed(2)}
                    %)
                  </p>
                  <p>
                    Card Orders: {totalCardOrders} (
                    {(
                      (totalCardOrders / (totalCashOrders + totalCardOrders)) *
                      100
                    ).toFixed(2)}
                    %)
                  </p>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.averageModal}
                onHide={this.handleCloseAverageModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Cash Avg: $
                    {Number(totalCashOrders) !== 0
                      ? (Number(totalCash) / Number(totalCashOrders)).toFixed(2)
                      : 0}
                  </p>
                  <p>
                    Card Avg: $
                    {Number(totalCardOrders) !== 0
                      ? (Number(totalCard) / Number(totalCardOrders)).toFixed(2)
                      : 0}
                  </p>
                </Modal.Body>
              </Modal>

              <Row>
                <Col xs={12} md={4} lg={4}>
                  <h5>Refunds: ${Number(canceled_sales).toFixed(2)}</h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowRefundsModal}
                  >
                    See Details
                  </p>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <h5>Refunded Orders: {canceled_orders}</h5>{" "}
                  <p
                    style={{ color: "#2959a5", cursor: "pointer" }}
                    onClick={this.handleShowRefundsOrderModal}
                  >
                    See Details
                  </p>
                </Col>
              </Row>
              <Modal
                show={this.state.refundsModal}
                onHide={this.handleCloseRefundsModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Refunds: ${Number(canceled_sales).toFixed(2)}</p>
                  <p>
                    Total Sales Less Refund: $
                    {Number(
                      Number(total_sales) - Number(canceled_sales)
                    ).toFixed(2)}
                  </p>
                  <p>
                    Total Cash Less Refund: $
                    {Number(
                      Number(totalCash) - Number(canceled_sales_cash)
                    ).toFixed(2)}
                  </p>
                  <p>
                    Total Card Less Refund: $
                    {Number(
                      Number(totalCard) - Number(canceled_sales_card)
                    ).toFixed(2)}
                  </p>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.refundsOrderModal}
                onHide={this.handleCloseRefundsOrderModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Sales Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Refunded Orders: ${canceled_orders}</p>
                  <p>
                    Total Orders Less Refunded Orders: $
                    {total_orders - canceled_orders}
                  </p>
                </Modal.Body>
              </Modal>

              <Row>
                {/* Percentage Change - Last Year */}
                <Col xs={12} md={4} lg={4}>
                  <h5>
                    Percentage Change - Last Year Based on{" "}
                    {this.state.lastYearDatesInfo.firstDate} -{" "}
                    {this.state.lastYearDatesInfo.lastDate}:
                  </h5>
                </Col>
                <Col xs={12} md={8} lg={8}>
                  {percentageChangeLastYear !== 0 ? (
                    <>
                      {percentageChangeLastYear > 0 ? (
                        <h5 style={{ color: "green" }}>
                          Up by % {Number(percentageChangeLastYear).toFixed(2)}
                        </h5>
                      ) : (
                        <h5 style={{ color: "red" }}>
                          Down by %{" "}
                          {Number(percentageChangeLastYear).toFixed(2)}
                        </h5>
                      )}
                    </>
                  ) : (
                    <h5>Can't find similar events to compare.</h5>
                  )}
                </Col>

                {/* Percentage Change - Last Month */}
                <Col xs={12} md={4} lg={4}>
                  <h5>
                    Percentage Change - Last Month Based on{" "}
                    {this.state.lastMonthDatesInfo.firstDate} -{" "}
                    {this.state.lastMonthDatesInfo.lastDate}:
                  </h5>
                </Col>
                <Col xs={12} md={8} lg={8}>
                  {percentageChangeLastMonth !== 0 ? (
                    <>
                      {percentageChangeLastMonth > 0 ? (
                        <h5 style={{ color: "green" }}>
                          Up by % {Number(percentageChangeLastMonth).toFixed(2)}
                        </h5>
                      ) : (
                        <h5 style={{ color: "red" }}>
                          Down by %{" "}
                          {Number(percentageChangeLastMonth).toFixed(2)}
                        </h5>
                      )}
                    </>
                  ) : (
                    <h5>No Sale during last week.</h5>
                  )}
                </Col>

                {/* Percentage Change - Last Week */}
                <Col xs={12} md={4} lg={4}>
                  <h5>
                    Percentage Change Based on{" "}
                    {this.state.lastWeekDatesInfo.firstDate} -{" "}
                    {this.state.lastMonthDatesInfo.lastDate}:
                  </h5>
                </Col>
                <Col xs={12} md={8} lg={8}>
                  {percentageChangeLastWeek !== 0 ? (
                    <>
                      {percentageChangeLastWeek > 0 ? (
                        <h5 style={{ color: "green" }}>
                          Up by % {Number(percentageChangeLastWeek).toFixed(2)}
                        </h5>
                      ) : (
                        <h5 style={{ color: "red" }}>
                          Down by %{" "}
                          {Number(percentageChangeLastWeek).toFixed(2)}
                        </h5>
                      )}
                    </>
                  ) : (
                    <h5>
                      Can't find similar events from last week to compare.
                    </h5>
                  )}
                </Col>
              </Row>
              <Row>
                <MealStats meal_freq={this.state.meal_freq}></MealStats>
              </Row>
              <hr />

              {this.state.selectedVendorId != undefined ? (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventOrganiserBarGraph
                      vendorName={this.state.salesByVendorName}
                      vendorData={this.state.salesByVendorTotal}
                      isBool={true}
                    ></EventOrganiserBarGraph>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <EventOrganiserBarGraph
                    vendorName={this.state.salesByVendorName}
                    vendorData={this.state.salesByVendorTotal}
                    isBool={true}
                  ></EventOrganiserBarGraph>
                </Row>
              )}

              <Row>
                <EventPOSChart
                  posName={this.state.salesByPOSName}
                  posSalesData={this.state.salesByPOSTotal}
                  isBool={true}
                ></EventPOSChart>
              </Row>

              {this.state.selectedVendorId != undefined ? (
                <Row>
                  <Col lg={{ span: 8, offset: 2 }}>
                    <EventSalesBarGraph
                      vendorName={this.state.salesByEventName}
                      vendorData={this.state.salesByEventTotal}
                      isBool={true}
                    ></EventSalesBarGraph>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <EventSalesBarGraph
                    vendorName={this.state.salesByEventName}
                    vendorData={this.state.salesByEventTotal}
                    isBool={true}
                  ></EventSalesBarGraph>
                </Row>
              )}
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Bar data={data} options={options} />
                </Col>
              </Row>

              <SalesTable sales_by_vendor_obj={salesByVendorObj}></SalesTable>
              <SalesByEventTabel
                sales_by_vendor_obj={salesByEventObj}
              ></SalesByEventTabel>

              <div className="my-3">
                <h3>Items Sold:</h3>
                {this.state.meal_freq[0] && (
                  <TableData tableData={updatedMealFreq}></TableData>
                )}
              </div>
              <div
                style={{
                  height: window.innerHeight / 3,
                  overflow: "scroll",
                  borderWidth: 1,
                  borderColor: "black",
                  borderStyle: "solid",
                }}
              ></div>
            </Container>
            <Footer />
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={require("../../assets/img/loading.gif")}></img>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AdminExternalOperatorsDashboardScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
