import React, { Component } from "react";
import Footer from "../../components/UI/Footer";
import {
  Navbar,
  Nav,
  Form,
  Button,
  FormControl,
  NavDropdown,
  Image,
  Container,
  Row,
  Col,
  Carousel,
  Modal,
  Card,
  Media,
  ButtonGroup,
} from "react-bootstrap";
import ErrorFallbackComponent from "../../screens/ErrorFallBackScreen";
import * as Sentry from "@sentry/react";
import { theme } from "../../components/UI/theme";
import { connect } from "react-redux";
import "./style.css";

import { apiPaths } from "../../services/apiPath";

import config from "../../services/apiConfig";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import ReadMoreReact from "read-more-react";

import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../store";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { responsiveFontSizes, withStyles } from "@material-ui/core/styles";
import ResponsiveNavBar from "../../components/UI/ResponsiveNavBar";
import { hasEventExpired } from "../../helper";
import { AWS_URL } from "../../assets/Constants";
import { sizeHeight } from "@mui/system";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

class AllEventsScreen extends Component {
  state = {
    events: new Array(),
    isLoading: false,
  };

  getAllEvents = () => {
    this.setState({ isLoading: true });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "all_events_without_menu",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.devUrl + apiPaths.events, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.success == true) {
          const sortedEvents = result.events
            .filter((event) => event.event_type !== "private")
            .sort((b, a) =>
              moment(
                moment(a.start_date, "DD MMM YYYY").format("YYYY-MM-DD")
              ).diff(
                moment(
                  moment(b.start_date, "DD MMM YYYY").format("YYYY-MM-DD")
                ),
                "days"
              )
            );

          this.setState({ events: sortedEvents });
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        this.setState({ isLoading: false });
      });

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var url = config.BASE_URI + apiPaths.customerEvents;
    // var raw = JSON.stringify(
    //     {
    //         "payload":
    //         {
    //             "body":
    //             {
    //                 "query_type": 'all_events',

    //             }
    //         }
    //     });

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    // };

    // fetch(url, requestOptions)
    //     .then(response => response.json())
    //     .then(response => {
    //         if (response.success == true) {
    //             const sortedEvents = response.events.sort((b, a) => moment(moment(a.start_date, 'DD MMM YYYY').format('YYYY-MM-DD')).diff(moment(moment(b.start_date, 'DD MMM YYYY').format('YYYY-MM-DD')), 'days'))
    //             this.setState({events:sortedEvents})
    //         }
    //     })
  };
  componentDidMount() {
    this.getAllEvents();
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  render() {
    const { events, isLoading } = this.state;
    const { appReducer } = store.getState();
    const { eventTicket, products } = appReducer;
    const { loggedIn, username, userId, dispatch } = this.props;
    return (
      <div>
        <ResponsiveNavBar
          showBack={false}
          backUrl={""}
          ticketState={eventTicket}
          products={products}
          loggedIn={loggedIn}
          username={username}
          userd={userId}
          dispatch={dispatch}
        ></ResponsiveNavBar>
        {!isLoading ? (
          <>
            <Container>
              <Row style={{ marginTop: 30 }}>
                <Col xs={12}>
                  <h3>What's Trucking</h3>
                </Col>
              </Row>
              {/* <Button
                style={{ marginRight: 20 }}
                onClick={() => {
                  window.location.replace("/organiser");
                }}
              >
                Organiser Login
              </Button> */}
              {/* <Button
                onClick={() => {
                  window.location.replace("/operator-login");
                }}
              >
                Vendor Login
              </Button> */}
              {events.map((item, index) => {
                return (
                  <>
                    {index % 4 == 0 ? (
                      <Row>
                        <Col
                          xs={12}
                          md={6}
                          lg={3}
                          style={{ cursor: "pointer", marginTop: 30 }}
                          onClick={() => {
                            window.location.href = "/event:" + events[index].id;
                          }}
                        >
                          <Card className="card-container-events scroll">
                            <div
                              className="cover-image"
                              style={{ overflow: "hidden" }}
                            >
                              <Card.Img
                                variant="top"
                                src={
                                  events[index] &&
                                  events[index].cover &&
                                  events[index].cover != "" &&
                                  events[index].cover != null
                                    ? AWS_URL + "/" + events[index].cover
                                    : require("../../assets/img/default_cover.jpg")
                                }
                              />
                            </div>
                            <Card.Body>
                              <Card.Title
                                style={{ fontWeight: 400, fontSize: 25 }}
                                className=".me-5"
                              >
                                {events[index].name}
                              </Card.Title>
                              <Card.Subtitle
                                className="mb-2 "
                                style={{ marginTop: 5 }}
                              >
                                <img
                                  style={{ width: 20 }}
                                  src={require("../../assets/img/date-icon.png")}
                                ></img>
                                {events[index].start_date} -{" "}
                                {events[index].end_date}
                              </Card.Subtitle>
                              <Card.Subtitle className="mb-2 ">
                                <img
                                  style={{ width: 20 }}
                                  src={require("../../assets/img/time-icon.png")}
                                ></img>{" "}
                                {events[index].start_time} -{" "}
                                {events[index].end_time}
                              </Card.Subtitle>
                              <Card.Subtitle
                                style={{ textTransform: "capitalize" }}
                                className="mb-2"
                              >
                                <img
                                  style={{ width: 20 }}
                                  src={require("../../assets/img/event-type-icon.png")}
                                ></img>
                                {events[index].event_type}
                              </Card.Subtitle>
                              <Card.Subtitle className="mb-2 ">
                                <img
                                  style={{ width: 20 }}
                                  src={require("../../assets/img/location-icon.png")}
                                ></img>
                                {events[index].event_location}
                              </Card.Subtitle>
                              {!hasEventExpired(events[index], "food") &&
                              events[index].vendor.length > 0 ? (
                                <div
                                  style={{
                                    display: "block",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "#dfdfdf",
                                    textAlign: "center",
                                  }}
                                >
                                  <h6>
                                    <b>Participating Trucks</b>
                                  </h6>
                                  {events[index].vendor.map(
                                    (vendor, vendorIndex) => {
                                      if (vendorIndex > 1) {
                                        if (events[index].id == 20) {
                                        }
                                        return <></>;
                                      }
                                      if (events[index].id == 20) {
                                      }
                                      return (
                                        <Media className={"card-effect"}>
                                          <img
                                            alt={vendor.title}
                                            height={50}
                                            width={50}
                                            className="mr-3"
                                            src={
                                              vendor.logo &&
                                              vendor.logo != null &&
                                              vendor.logo != ""
                                                ? AWS_URL + "/" + vendor.logo
                                                : require("../../assets/img/empty_logo.png")
                                            }
                                            style={{ overflow: "hidden" }}
                                          />
                                          <Media.Body>
                                            <h6
                                              style={{
                                                marginTop: 15,
                                                textAlign: "left",
                                              }}
                                            >
                                              {vendor.title}
                                            </h6>
                                          </Media.Body>
                                        </Media>
                                      );
                                    }
                                  )}
                                  <p style={{ color: "blue" }}>
                                    {events[index].vendor.length - 2 > 0 ? (
                                      <>
                                        + {events[index].vendor.length - 2} more
                                      </>
                                    ) : null}
                                  </p>
                                </div>
                              ) : null}
                              <Card.Text>{events[index].description}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={3}
                          style={{ cursor: "pointer", marginTop: 30 }}
                          onClick={() => {
                            window.location.href =
                              "/event:" + events[index + 1].id;
                          }}
                        >
                          {events[index + 1] ? (
                            <Card className="card-container-events scroll">
                              <div
                                className="cover-image"
                                style={{ overflow: "hidden" }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={
                                    events[index + 1] &&
                                    events[index + 1].cover != "" &&
                                    events[index + 1].cover != null
                                      ? AWS_URL + "/" + events[index + 1].cover
                                      : require("../../assets/img/default_cover.jpg")
                                  }
                                />
                              </div>
                              <Card.Body>
                                <Card.Title
                                  style={{ fontWeight: 400, fontSize: 25 }}
                                  className=".me-5"
                                >
                                  {events[index + 1] && events[index + 1].name}
                                </Card.Title>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/date-icon.png")}
                                  ></img>
                                  {events[index + 1].start_date} -{" "}
                                  {events[index + 1].end_date}
                                </Card.Subtitle>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/time-icon.png")}
                                  ></img>
                                  {events[index + 1].start_time} -{" "}
                                  {events[index + 1].end_time}
                                </Card.Subtitle>
                                <Card.Subtitle
                                  style={{ textTransform: "capitalize" }}
                                  className="mb-2"
                                >
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/event-type-icon.png")}
                                  ></img>
                                  {events[index + 1].event_type}
                                </Card.Subtitle>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/location-icon.png")}
                                  ></img>
                                  {events[index + 1].event_location}
                                </Card.Subtitle>
                                {!hasEventExpired(events[index + 1], "food") &&
                                events[index + 1].vendor.length > 0 ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderWidth: 1,
                                      borderStyle: "solid",
                                      borderColor: "#dfdfdf",
                                      textAlign: "center",
                                    }}
                                  >
                                    <h6>
                                      <b>Participating Trucks</b>
                                    </h6>
                                    {events[index + 1].vendor.map(
                                      (vendor, vendorIndex) => {
                                        if (vendorIndex > 1) {
                                          return;
                                        }
                                        return (
                                          <Media className={"card-effect"}>
                                            <img
                                              alt={vendor.title}
                                              height={50}
                                              width={50}
                                              className="mr-3"
                                              src={
                                                vendor.logo &&
                                                vendor.logo != null &&
                                                vendor.logo != ""
                                                  ? AWS_URL + "/" + vendor.logo
                                                  : require("../../assets/img/empty_logo.png")
                                              }
                                              style={{ overflow: "hidden" }}
                                            />
                                            <Media.Body>
                                              <h6
                                                style={{
                                                  marginTop: 15,
                                                  textAlign: "left",
                                                }}
                                              >
                                                {vendor.title}
                                              </h6>
                                            </Media.Body>
                                          </Media>
                                        );
                                      }
                                    )}
                                    <p style={{ color: "blue" }}>
                                      {events[index + 1].vendor.length - 2 >
                                      0 ? (
                                        <>
                                          +{" "}
                                          {events[index + 1].vendor.length - 2}{" "}
                                          more
                                        </>
                                      ) : null}
                                    </p>
                                  </div>
                                ) : null}
                                <Card.Text>
                                  {events[index + 1].description}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          ) : null}
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={3}
                          style={{ cursor: "pointer", marginTop: 30 }}
                          onClick={() => {
                            window.location.href =
                              "/event:" + events[index + 2].id;
                          }}
                        >
                          {events[index + 2] ? (
                            <Card className="card-container-events scroll">
                              <div
                                className="cover-image"
                                style={{ overflow: "hidden" }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={
                                    events[index + 2] &&
                                    events[index + 2].cover != "" &&
                                    events[index + 2].cover != null
                                      ? AWS_URL + "/" + events[index + 2].cover
                                      : require("../../assets/img/default_cover.jpg")
                                  }
                                />
                              </div>
                              <Card.Body>
                                <Card.Title
                                  style={{ fontWeight: 400, fontSize: 25 }}
                                  className=".me-5"
                                >
                                  {events[index + 2] && events[index + 2].name}
                                </Card.Title>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/date-icon.png")}
                                  ></img>
                                  {events[index + 2].start_date} -{" "}
                                  {events[index + 2].end_date}
                                </Card.Subtitle>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/time-icon.png")}
                                  ></img>
                                  {events[index + 2].start_time} -{" "}
                                  {events[index + 2].end_time}
                                </Card.Subtitle>
                                <Card.Subtitle
                                  style={{ textTransform: "capitalize" }}
                                  className="mb-2"
                                >
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/event-type-icon.png")}
                                  ></img>
                                  {events[index + 2].event_type}
                                </Card.Subtitle>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/location-icon.png")}
                                  ></img>
                                  {events[index + 2].event_location}
                                </Card.Subtitle>
                                {!hasEventExpired(events[index + 2], "food") &&
                                events[index + 2].vendor.length > 0 ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderWidth: 1,
                                      borderStyle: "solid",
                                      borderColor: "#dfdfdf",
                                      textAlign: "center",
                                    }}
                                  >
                                    <h6>
                                      <b>Participating Trucks</b>
                                    </h6>
                                    {events[index + 2].vendor.map(
                                      (vendor, vendorIndex) => {
                                        if (vendorIndex > 1) {
                                          return;
                                        }
                                        return (
                                          <Media className={"card-effect"}>
                                            <img
                                              alt={vendor.title}
                                              height={50}
                                              width={50}
                                              className="mr-3"
                                              src={
                                                vendor.logo &&
                                                vendor.logo != null &&
                                                vendor.logo != ""
                                                  ? AWS_URL + "/" + vendor.logo
                                                  : require("../../assets/img/empty_logo.png")
                                              }
                                              style={{ overflow: "hidden" }}
                                            />
                                            <Media.Body>
                                              <h6
                                                style={{
                                                  marginTop: 15,
                                                  textAlign: "left",
                                                }}
                                              >
                                                {vendor.title}
                                              </h6>
                                            </Media.Body>
                                          </Media>
                                        );
                                      }
                                    )}
                                    <p style={{ color: "blue" }}>
                                      {events[index + 2].vendor.length - 2 >
                                      0 ? (
                                        <>
                                          +{" "}
                                          {events[index + 2].vendor.length - 2}{" "}
                                          more
                                        </>
                                      ) : null}
                                    </p>
                                  </div>
                                ) : null}
                                <Card.Text>
                                  {events[index + 2].description}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          ) : null}
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={3}
                          style={{ cursor: "pointer", marginTop: 30 }}
                          onClick={() => {
                            window.location.href =
                              "/event:" + events[index + 3].id;
                          }}
                        >
                          {events[index + 3] ? (
                            <Card className="card-container-events scroll">
                              <div
                                className="cover-image"
                                style={{ overflow: "hidden" }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={
                                    events[index + 3] &&
                                    events[index + 3].cover &&
                                    events[index + 3].cover != "" &&
                                    events[index + 3].cover != null
                                      ? AWS_URL + "/" + events[index + 3].cover
                                      : require("../../assets/img/default_cover.jpg")
                                  }
                                />
                              </div>
                              <Card.Body>
                                <Card.Title
                                  style={{ fontWeight: 400, fontSize: 25 }}
                                  className=".me-5"
                                >
                                  {events[index + 3] && events[index + 3].name}
                                </Card.Title>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/date-icon.png")}
                                  ></img>
                                  {events[index + 3].start_date} -{" "}
                                  {events[index + 3].end_date}
                                </Card.Subtitle>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/time-icon.png")}
                                  ></img>
                                  {events[index + 3].start_time} -{" "}
                                  {events[index + 3].end_time}
                                </Card.Subtitle>
                                <Card.Subtitle
                                  style={{ textTransform: "capitalize" }}
                                  className="mb-2"
                                >
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/event-type-icon.png")}
                                  ></img>
                                  {events[index + 3].event_type}
                                </Card.Subtitle>
                                <Card.Subtitle className="mb-2 ">
                                  <img
                                    style={{ width: 20 }}
                                    src={require("../../assets/img/location-icon.png")}
                                  ></img>
                                  {events[index + 3].event_location}
                                </Card.Subtitle>
                                {!hasEventExpired(events[index + 3], "food") &&
                                events[index + 3].vendor.length > 0 ? (
                                  <div
                                    style={{
                                      display: "block",
                                      borderWidth: 1,
                                      borderStyle: "solid",
                                      borderColor: "#dfdfdf",
                                      textAlign: "center",
                                    }}
                                  >
                                    <h6>
                                      <b>Participating Trucks</b>
                                    </h6>
                                    {events[index + 3].vendor.map(
                                      (vendor, vendorIndex) => {
                                        if (vendorIndex > 1) {
                                          return;
                                        }
                                        return (
                                          <Media className={"card-effect"}>
                                            <img
                                              alt={vendor.title}
                                              height={50}
                                              width={50}
                                              className="mr-3"
                                              src={
                                                vendor.logo &&
                                                vendor.logo != null &&
                                                vendor.logo != ""
                                                  ? AWS_URL + "/" + vendor.logo
                                                  : require("../../assets/img/empty_logo.png")
                                              }
                                              style={{ overflow: "hidden" }}
                                            />
                                            <Media.Body>
                                              <h6
                                                style={{
                                                  marginTop: 15,
                                                  textAlign: "left",
                                                }}
                                              >
                                                {vendor.title}
                                              </h6>
                                            </Media.Body>
                                          </Media>
                                        );
                                      }
                                    )}
                                    <p style={{ color: "blue" }}>
                                      {events[index + 3].vendor.length - 2 >
                                      0 ? (
                                        <>
                                          +{" "}
                                          {events[index + 3].vendor.length - 2}{" "}
                                          more
                                        </>
                                      ) : null}
                                    </p>
                                  </div>
                                ) : null}
                                <Card.Text>
                                  {events[index + 3].description}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          ) : null}
                        </Col>
                      </Row>
                    ) : null}
                  </>
                );
              })}
            </Container>
            <Footer />
          </>
        ) : (
          <>
            <div style={{ textAlign: "center" }}>
              <img src={require("../../assets/img/loading.gif")}></img>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
  };
}

export default connect(mapStateToProps)(
  Sentry.withErrorBoundary(AllEventsScreen, {
    fallback: <ErrorFallbackComponent />,
  })
);
