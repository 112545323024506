import React, { Component, useCallback } from "react";
import Footer from "../../../components/UI/Footer";
import StarRating from "../../../components/StarRating";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import CsvDownloadButton from "react-json-to-csv";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PDFViewer } from "@react-pdf/renderer";
import EventReport from "./EventReport";
import * as XLSX from "xlsx";
import "./style.css";
import SalesReport from "./SalesReport";
import * as Sentry from "@sentry/react";
import {
  Container,
  Paper,
  List,
  ListItem,
  Card,
  Button,
  Box,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { apiPaths } from "../../../services/apiPath";
import AdminNavBar from "../../../components/UI/AdminNavBar";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import {
  store,
  saveToLocalStorage,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../../store";
import config from "../../../services/apiConfig";
import EventDetail from "../../../components/CreateEventComponent/EventModal";
import Dialog from "@mui/material/Dialog";
import { connect } from "react-redux";
import {
  getComfirmedVendors,
  getSelectedEoi,
} from "../../../actions/EventOrganiser/action";
import { AWS_URL } from "../../../assets/Constants";

class ViewPastEvents extends Component {
  state = {
    vendor_data: [
      {
        title: "Hot Churros",
        total_revenue: 1000,
        utility_expenses: 100,
        commission_percentage: 25,
        total_commission: 250,
        vendor_payout: 600,
        amfvg_fee: 100,
        transaction_fee: 50,
      },
      {
        title: "I luv Dumplings",
        total_revenue: 1000,
        utility_expenses: 100,
        commission_percentage: 25,
        total_commission: 250,
        vendor_payout: 600,
        amfvg_fee: 100,
        transaction_fee: 50,
      },
    ],
    salesFigure: [],
    email:
      getFromLocalStorage("email") != "undefined" &&
      getFromLocalStorage("email") != "" &&
      getFromLocalStorage("email") != null
        ? getFromLocalStorage("email")
        : "",
    password:
      getFromLocalStorage("password") != "undefined" &&
      getFromLocalStorage("password") != "" &&
      getFromLocalStorage("password") != null
        ? getFromLocalStorage("password")
        : "",
    expanded: null,
    isSuperAdminLoggedIn: "false",
    isOrganiserLoggedIn:
      getFromLocalStorage("isOrganiserLoggedIn") != "undefined" &&
      getFromLocalStorage("isOrganiserLoggedIn") != "" &&
      getFromLocalStorage("isOrganiserLoggedIn") != null
        ? getFromLocalStorage("isOrganiserLoggedIn")
        : "false",
    vendorName:
      getFromLocalStorage("vendorName") != "undefined" &&
      getFromLocalStorage("vendorName") != "" &&
      getFromLocalStorage("vendorName") != null
        ? getFromLocalStorage("vendorName")
        : "",
    vendorData: [],
    eventList: [],
    selectedVendor: {},
    confirmVendors: [],
    selectEvent: {},
    defaultLink: "../../../assets/img/welcome-logo.png",
    selectedEventId: 0,
    selectedVendorId: 0,
    selectedComment: "",
    selectedRating: 0,
  };
  handleChange = (panel, i) => (event, isExpanded) => {
    this.setState((prevState) => ({
      expanded: isExpanded
        ? panel
        : prevState.expanded === panel
        ? null
        : panel,
      selectedVendorId: panel,
      selectedComment: Array.isArray(i) && i.length > 0 ? i[0].comments : "",
      selectedCommentTemp:
        Array.isArray(i) && i.length > 0 ? i[0].comments : "",
      selectedRating: Array.isArray(i) && i.length > 0 ? i[0].ratings_value : 0,
    }));
  };

  getOrders = (eventId) => {
    const { email, password } = this.state;
    var url = config.BASE_URI + apiPaths.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_sales_figures",
          email: email,
          password: password,
          event: eventId,
          vendor: "0",
          selected_start_order_id: "-1",
          selected_end_order_id: "-1",
          selected_date: "-1",
        },
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        console.log(dataResponse);
        if (dataResponse.success == true) {
          this.setState({
            salesFigure: dataResponse,
          });
          console.log(dataResponse);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  getTargetedEvents = (id) => {
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.organiserData;
    headers.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_participated_vendors",
          event_id: id,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          selectEvent: response.data,
        });
        // this.getAllVendors(vendorList);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };
  postReviews = () => {
    var url = config.BASE_URI + apiPaths.organiserData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      payload: {
        body: {
          query_type: "add_vendor_event_ratings",
          event_id: parseInt(this.state.selectedEventId),
          vendor_id: this.state.selectedVendorId,
          ratings: this.state.selectedRating,
          comments: this.state.selectedCommentTemp,
        },
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        if (dataResponse.success === true) {
          alert("Review submitted successfully!");
        } else {
          alert("Please try again!");
        }
      });
  };

  getEvents = async () => {
    let newEvent = [];
    const headers = new Headers();
    const url = config.BASE_URI + apiPaths.organiserData;
    const emailID = getFromLocalStorage("email");
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payload: {
        body: {
          query_type: "get_past_events",
          organiser_id: JSON.parse(getFromLocalStorage("Organiser"))[0].id,
        },
      },
    });
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((dataResponse) => {
        this.setState({ eventList: dataResponse.data });
      });
  };
  componentDidMount = () => {
    this.getEvents();
  };

  logout = () => {
    deleteFromLocalStorage("isOperatorLoggedIn", "false");
    deleteFromLocalStorage("isOrganiserLoggedIn", "false");
    deleteFromLocalStorage("vendorName", "");
    deleteFromLocalStorage("email", "");
    deleteFromLocalStorage("password", "");
    deleteFromLocalStorage("selectedEventId", "0");
    this.setState({
      isSuperAdminLoggedIn: "false",
      isOperatorLoggedIn: "false",
      isOrganiserLoggedIn: "false",
    });
    window.location.replace("/events");
  };
  render() {
    const {
      isSuperAdminLoggedIn,
      isOrganiserLoggedIn,
      vendorName,
      vendorData,
      expanded,
      selectEvent,
      vendor_data,
      salesFigure,
    } = this.state;

    const csvDataDownload = vendor_data.map((vendor) => {
      console.log(vendor);
      return {
        Title: vendor.title,
        "Total Revenue": vendor.total_revenue,
        "Utility Expenses": vendor.utility_expenses,
        "Commission Percentage": vendor.commission_percentage,
        "Total Commission": vendor.total_commission,
        "Vendor Payout": vendor.vendor_payout,
        "AMFVG Fee": vendor.amfvg_fee,
        "Transaction Fee": vendor.transaction_fee,
      };
    });
    const jsonToXLSX = (data, filename) => {
      const worksheet = XLSX.utils.json_to_sheet(data);

      // Set column widths (double the default width)
      const columnWidths = [
        { wch: 20 },
        { wch: 26 },
        { wch: 26 },
        { wch: 26 },
        { wch: 26 },
        { wch: 26 },
        { wch: 26 },
        { wch: 26 },
      ];
      worksheet["!cols"] = columnWidths;

      // Set cell background color to yellow
      const yellowCellStyle = {
        fill: {
          fgColor: { rgb: "FFFFFF00" }, // Yellow color
        },
      };

      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let col = range.s.c; col <= range.e.c; col++) {
        for (let row = range.s.r; row <= range.e.r; row++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const cellAddress = XLSX.utils.encode_row(row) + cell.substring(1);

          // Create cell if it doesn't exist
          if (!worksheet[cellAddress]) {
            worksheet[cellAddress] = {};
          }

          worksheet[cellAddress].s = yellowCellStyle;
        }
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };
    const setNewRating = (data) => {
      this.setState({ selectedRating: data * 2 });
    };
    return (
      <>
        <div>
          <AdminNavBar
            style={{ margin: "auto" }}
            isSuperAdminLoggedIn={isSuperAdminLoggedIn}
            isOrganiserLoggedIn={isOrganiserLoggedIn}
            logout={this.logout}
            vendorName={vendorName}
          ></AdminNavBar>
          <EventDetail
            open={this.state.isOpen}
            handleClose={this.onClose}
            vendorDetail={this.state.selectedVendor}
            confirmVendor={this.onComfirm}
            rejectVendor={this.onReject}
          />
          <Container style={{ minHeight: "600px" }}>
            <Paper
              elevation={3}
              square
              style={{
                margin: "auto",
                paddingTop: 20,
                width: "80%",
                minHeight: "800px",
              }}
            >
              <Col
                xs={12}
                md={12}
                lg={12}
                className="create-events-basicInfo"
                style={{ margin: "auto", padding: 10 }}
              >
                <Col>
                  <button
                    className="center-button-report cent"
                    onClick={() =>
                      window.location.replace("organiser-analytics")
                    }
                    style={{
                      background: "#ef3e6d",
                      backgroundColor: "#ef3e6d",
                      borderRadius: "6px",
                      border: "1px solid #ef3e6d",
                      display: "inline-block",
                      cursor: "pointer",
                      color: "#ffffff",
                      fontSize: "15px",
                      fontWeight: "bold",
                      padding: "6px 24px",
                      textDecoration: "none",
                    }}
                  >
                    View Analytics
                  </button>
                </Col>
                <FormControl style={{ width: "100%", paddingTop: "30px" }}>
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ paddingTop: "30px" }}
                  >
                    Please Select your event
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Please select your event"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.selectEvent.name}
                    style={{ height: 60 }}
                    onChange={(e) => {
                      this.getTargetedEvents(e.target.value);
                      this.setState({ selectedEventId: e.target.value });
                      saveToLocalStorage(
                        "selectedEventId",
                        "" + e.target.value
                      );
                      console.log(e.target.value);
                      this.getOrders(e.target.value);
                    }}
                  >
                    {this.state.eventList?.map((event, key) => (
                      <MenuItem value={event.id} index={key}>
                        {event.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <div className="container-accordion">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <strong>REPORTS</strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {selectEvent && (
                          <div>
                            <div className="center-button-report">
                              {/* <CsvDownloadButton
                                data={csvDataDownload}
                                onClick={() =>
                                  jsonToXLSX(csvDataDownload, "event_report")
                                }
                                filename="event_report.csv"
                                className="button-report"
                                style={{
                                  background: "#ef3e6d",
                                  backgroundColor: "#ef3e6d",
                                  borderRadius: "6px",
                                  border: "1px solid #ef3e6d",
                                  display: "inline-block",
                                  cursor: "pointer",
                                  color: "#ffffff",
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  padding: "6px 24px",
                                  textDecoration: "none",
                                }}
                              >
                                Event Report
                              </CsvDownloadButton> */}
                            {/* </div> */}
                            {/* <div>
                              <PDFViewer>
                                <EventReport salesFigure={salesFigure} />
                              </PDFViewer>
                            </div>
                            <SalesReport data={salesFigure} />
                          </div>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div> */}
                <div className="container-accordion">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={{ textAlign: "center" }}>
                        <strong>REVIEWS</strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {selectEvent && selectEvent.length > 0 ? (
                          selectEvent.map((event) => (
                            <Row key={event.id}>
                              <Box
                                style={{
                                  position: "relative",
                                  left: "10%",

                                  borderRadius: 30,
                                  paddingTop: 30,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <Accordion
                                    style={{ width: "100%" }}
                                    expanded={expanded === event.id}
                                    onChange={this.handleChange(
                                      event.id,
                                      event.ratings
                                    )}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      {/* <Typography
                                        sx={{ width: "33%", flexShrink: 0 }}
                                      >
                                        Vendor Name
                                      </Typography> */}
                                      <Typography sx={{ color: "#fff" }}>
                                        {event.title}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        <StarRating
                                          ratingNumber={
                                            event.ratings.length > 0
                                              ? event.ratings[0].ratings_value
                                              : 0
                                          }
                                          setNewRating={setNewRating}
                                        />
                                      </Typography>
                                      <Typography>
                                        <textarea
                                          value={this.state.selectedCommentTemp}
                                          onChange={(e) =>
                                            this.setState({
                                              selectedCommentTemp:
                                                e.target.value,
                                            })
                                          }
                                        />
                                      </Typography>
                                      <Button
                                        style={{
                                          width: "100%",
                                          marginBottom: 20,
                                          marginTop: 10,
                                          backgroundColor: "#ef3f6d",
                                        }}
                                        className="button-enabled-pink-style"
                                        onClick={() => {
                                          this.postReviews();
                                        }}
                                      >
                                        Submit
                                      </Button>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </Box>
                            </Row>
                          ))
                        ) : (
                          <p>No events found.</p>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Paper>
          </Container>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventDetail: state.organiser.newEventDetail,
    EOI: state.organiser,
  };
}

export default connect(mapStateToProps)(ViewPastEvents);
